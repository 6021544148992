import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  fallbackLng: "en",
  resources: {
    ae: {
      // eslint-disable-next-line global-require
      translations: require("../locales/ae/translations.json"),
    },
    en: {
      // eslint-disable-next-line global-require
      translations: require("../locales/en/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
  returnObjects: true,
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
});

i18next.languages = ["ae", "en"];

export default i18next;
