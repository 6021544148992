/* eslint-disable import/prefer-default-export */
import ReactDOM from "react-dom/client";
import "intersection-observer";
import { DEPLOYMENT_ENVIRONMENT } from "./src/constants/env";

// export const wrapRootElement = ({ element }) => (
//   <React.StrictMode>{element}</React.StrictMode>
// );

// eslint-disable-next-line no-unused-vars
import "./src/i18n/i18next";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

const redirectToHomeIfNeeded = (location) => {
  if (
    location.pathname.startsWith("/wp-template-preview/") ||
    location.pathname.includes("/cf-preview")
  ) {
    window.location.replace("/404");
  }
};

export const onRouteUpdate = ({ location }) => {
  if (DEPLOYMENT_ENVIRONMENT === "production") {
    redirectToHomeIfNeeded(location);
  }
};

export const onClientEntry = () => {
  const { location } = window;
  if (DEPLOYMENT_ENVIRONMENT === "production") {
    redirectToHomeIfNeeded(location);
  }
};
